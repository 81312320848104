import type { NextWebVitalsMetric } from 'next/app';

declare const window: any; // Fixes Property 'gtag' does not exist on type 'Window & typeof globalThis'.ts(2339)

export const pageview = (url: string): void => {
  window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
    page_path: url,
  });
};

export const event = (metric: NextWebVitalsMetric): void => {
  const { id, name, label, value } = metric;
  window.gtag('event', name, {
    event_category:
      label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
};
